<template>
  <div>
    <!-- <el-carousel :height="imgHeight + 'px'">
      <el-carousel-item v-for="item in 4" :key="item">
        <img
          ref="imgHeight"
          style="width: 100%"
          src="../assets/banner.png"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel> -->
    <img class="banner" style="width: 100%" :src="bander" alt="" />
    <div class="box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/heatsearch' }"
          >产品服务</el-breadcrumb-item
        >

        <el-breadcrumb-item>产品列表</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <div style="display: flex; margin-top: 80px; ">
          <div
            @click="trage(item.id)"
            :class="
              'trage ' + (chooseList.indexOf(item.id) == -1 ? '' : 'trages')
            "
            v-for="(item, index) in list"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div style="display: flex;	margin-bottom: 56px;" 	v-for="(item,index) in list2" :key="index">
        <img
          style="
            width: 340px;
            height: 240px;
            background: #e40111;
            border-radius: 8px 8px 8px 8px;
          "
          :src="item.image"
          alt=""
        />
        <div style="margin-left: 60px; margin-left: 32px">
          <div
            style="
              font-size: 25px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #333333;
            "
          >
            {{item.product_name}}
          </div>
          <div style="margin: 20px 0" class="s1">
              {{item.product_blurb}}
          </div>
          <div
            style="
              width: 91px;
              height: 33px;
              border-radius: 8px 8px 8px 8px;
              opacity: 1;
              border: 1px solid #ba1c2b;
              font-size: 14px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #ba1c2b;
              display: flex;
              align-items: center;
              justify-content: center;
            "
			@click="deatile(item.id)"
          >
            查看详情
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import url from "../request/api";
export default {
  data() {
    return {
      id:false,
      ids:false,
      imgHeight: "",
      list: [
        // { name: "教学基础 设施建设" },
        // { name: "校园信息化和教学信息化" },
        // { name: "教育教学咨询和课程开发服务" },
        // { name: "产业学院 专业共建" },
      ],
      chooseList: [],
       imgurl: url.baseUrl,
       list2:[],
       bander:[]
    };
  },
  watch: {
    $route(to, from) {
      this.id=this.$route.query.lei
      this.lists()
     console.log(this.id,'212121id');

    },
  },
  mounted() {
    this.id=this.$route.query.lei

    // if(this.id){
      
      this.lists()
    this.banderlist()
    // }
    this.get_banner();
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
   created() {
   
  
  },
  methods: {
      banderlist(){
  this.$https
          .get(`/api/index/banner?name=product_banner`, )
          .then((res) => {
            
            console.log(res);
  // res.data.forEach((element,index) => {
  //         res.data[index] = this.imgurl+element
  //       });
            this.bander = res.data;
        });
    },
    lists(){
          this.$https
        .get(`api/product/product_list?cate_id=${this.id}`)
        .then((res) => {
         console.log(res);
         res.data.product_cate.forEach(element => {
           element.name=element.product_cate
          
         });

           this.chooseList=this.id
          this. lists2(this.id)
           this.list= res.data.product_cate

        //  res.data.product.forEach(element => {
        //    element.image=this.imgurl+element.image
          
        //  });
        //  this.list2=res.data.product
        //  setTimeout(  
        

        // ,1000);
    
        });
    },
     lists2(ids){
          this.$https
        .get(`api/product/product_list?cate_id=${ids}`)
        .then((res) => {
         console.log(res);
         res.data.product.forEach(element => {
           element.image=this.imgurl+element.image
          
         });
         this.list2=res.data.product
        //  setTimeout(  
        

        // ,1000);
    
        });
    },
	  deatile(id){
	this.$router.push({
						path: '/uploadnews',
            query: {detailsid: id}
					})

	  },
    trage(id) {
      this.chooseList = [];
      if (this.chooseList.indexOf(id) == -1) {
        this.chooseList.push(id);
        this.lists2(id)
      } else {
        this.chooseList.splice(this.chooseList.indexOf(id), 1);
      }
    },
    changenav(path, index) {
      this.$router.push({
        path: path,
      });
    },
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 480) / 1920;
      });
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>
<style scoped>
.trage {
  width: 330px;
  height: 58px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #ba1c2b;
  font-size: 18px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ba1c2b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 28px;
  margin-bottom: 80px;
  text-align: center;
}
.trages {
  background: #ba1c2b;
  color: #fff;
}
.box {
  width: 1300px;
  margin: 56px auto;
}
.s11 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.left {
  width: 740px;
}

.left .list {
  width: 740px;
  height: 133px;
  cursor: pointer;
  margin-bottom: 48px;
}

.left .list:hover .info .title {
  color: #0049c2;
}

.left .list .img {
  width: 240px;
  height: 133px;
  overflow: hidden;
}

.left .list .img img {
  width: 240px;
}

.left .list .info {
  width: 480px;
}

.left .list .info .title {
  font-size: 20px;
  color: #222222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.left .list .info .p {
  height: 42px;
  font-size: 16px;
  color: #666666;
  margin: 16px 0 23px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left .list .info .time {
  font-size: 14px;
  color: #999999;
}

.right {
  width: 440px;
  background: #fbfbfc;
  box-sizing: border-box;
  padding: 24px;
}

.right .titles {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
}

.right .list {
  height: 48px;
  cursor: pointer;
}

.right .list:hover .title {
  color: #0049c2;
}

.right .list .indexs {
  width: 30px;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.right .list .title {
  width: 270px;
  font-size: 16px;
  color: #333333;
  margin: 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.right .list .num {
  font-size: 16px;
  color: #999999;
  margin-left: 5px;
}
</style>